import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  FileInput,
  FileInputUploadItem,
} from "@new-black/lyra";

import { TLyraUploadModalProps } from "./lyra-upload-modal.types";

const getBase64EncodedString = (file?: FileInputUploadItem) => {
  return file?.data ? /base64,(.+)/.exec(file.data as string)?.[1] : undefined;
};

const LyraUploadModalContent = ({
  acceptedFileTypes,
  error,
  footerContent,
  formID,
  isLoading,
  onClose,
  onUpload,
  setFileData,
  setFileMimeType,
  setFileName,
  setFilePath,
  title,
}: Omit<TLyraUploadModalProps, "open">) => {
  const intl = useIntl();

  const [file, setFile] = useState<FileInputUploadItem>();

  useEffect(() => {
    // get encoded string data
    const base64encodedString = getBase64EncodedString(file);
    setFileData?.(base64encodedString);
    setFileName?.(file?.name);
    setFilePath?.(file?.path);
    setFileMimeType?.(file?.type);
  }, [file, setFileData, setFileMimeType, setFileName, setFilePath]);

  return (
    <Card>
      <CardHeader
        title={
          title ??
          intl.formatMessage({ id: "generic.label.upload-data", defaultMessage: "Upload data" })
        }
      />
      <CardContent>
        <FileInput
          acceptedFileTypes={acceptedFileTypes}
          errorMessage={error}
          onChange={(files) => {
            setFile(files?.[0]);
          }}
          readAsDataUrl
        />
        {footerContent}
      </CardContent>
      <CardFooter>
        <Button variant="secondary" onPress={onClose} isDisabled={isLoading}>
          <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
        </Button>

        <Button
          type={formID ? "submit" : "button"}
          form={formID}
          variant="primary"
          onPress={() => {
            const base64encodedString = getBase64EncodedString(file);
            if (base64encodedString) {
              onUpload?.(base64encodedString);
            }
          }}
          isDisabled={isLoading || !file}
          isLoading={isLoading}
        >
          <FormattedMessage id="generic.label.upload" defaultMessage="Upload" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default LyraUploadModalContent;
