import { useCallback, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import {
  getCustomFieldsQueryKeys,
  getCustomFieldTypesQueryKeys,
  useGetCustomFieldMetadataQuery,
  useGetCustomFieldsQuery,
} from "~/models/custom-fields";
import { CustomFieldType } from "~/types/custom-field";
import { getCustomFieldTypeIDLoaderHelper } from "~/util/get-custom-field-type-id";
import { useEditableCustomFields } from "~/util/hooks/use-editable-custom-fields";
import { queryClient } from "~/util/query-client";

export interface IUseCustomFieldsReturnType {
  customFields: EVA.Core.CustomFieldResponse[];
  isCustomFieldsLoading: boolean;
  isCustomFieldsLoadingWithoutResponse: boolean;
  errors: { [key: number]: string } | undefined;
  onCreateOrUpdateError: (error?: EVA.Core.ServiceError) => void;
  refetchCustomFields: () => void;
  getCustomFieldByID: (ID?: number) => EVA.Core.CustomFieldResponse | undefined;
  getCustomFieldErrorsByID: (ID?: number) => string | undefined;
}

export function useCustomFields(
  type?: CustomFieldType,
  ouId?: number,
  ouSetId?: number,
  enabled = true,
): IUseCustomFieldsReturnType {
  const [erroredCustomFieldsOptions, setErroredCustomFieldsOptions] =
    useState<EVA.Core.Management.GetCustomFieldsResponse["CustomFieldOptions"]>();

  const [errors, setErrors] = useState<{ [key: number]: string }>();

  const {
    data: customFieldTypeID,
    isFetching: isGetCustomFieldTypeIDLoading,
    isLoading: isGetCustomFieldTypeIDLoadingWithoutResponse,
  } = useQuery({
    queryFn: async () => getCustomFieldTypeIDLoaderHelper(queryClient, type),
    queryKey: getCustomFieldTypesQueryKeys.withKey([type]),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
  });

  const loaderKey = useMemo(
    () =>
      customFieldTypeID || ouId || ouSetId
        ? getCustomFieldsQueryKeys.withKey([
            ...(customFieldTypeID ? [customFieldTypeID.toString()] : []),
            ...(ouId ? [ouId.toString()] : []),
            ...(ouSetId ? [ouSetId.toString()] : []),
          ])
        : undefined,
    [customFieldTypeID, ouId, ouSetId],
  );

  const {
    data: customFields,
    isFetching: isGetCustomFieldsLoading,
    isLoading: isGetCustomFieldsLoadingWithoutResponse,
    refetch: refetchGetCustomFields,
  } = useGetCustomFieldsQuery(
    customFieldTypeID && enabled
      ? ({
          TypeID: customFieldTypeID,
          OrganizationUnitID: ouId,
          OrganizationUnitSetID: ouSetId,
        } as any) // TODO: remove cast after typings update
      : undefined,
    {
      loaderKey,
    },
  );

  const {
    data: customFieldMetadata,
    isFetching: isGetCustomFieldMetadataLoading,
    isLoading: isGetCustomFieldMetadataLoadingWithoutResponse,
    refetch: refetchGetCustomFieldMetadata,
  } = useGetCustomFieldMetadataQuery(customFieldTypeID && enabled ? {} : undefined, { loaderKey });

  const isCustomFieldsLoading = useMemo(
    () =>
      isGetCustomFieldTypeIDLoading || isGetCustomFieldsLoading || isGetCustomFieldMetadataLoading,
    [isGetCustomFieldMetadataLoading, isGetCustomFieldTypeIDLoading, isGetCustomFieldsLoading],
  );

  const isCustomFieldsLoadingWithoutResponse = useMemo(
    () =>
      isGetCustomFieldTypeIDLoadingWithoutResponse ||
      isGetCustomFieldsLoadingWithoutResponse ||
      isGetCustomFieldMetadataLoadingWithoutResponse,
    [
      isGetCustomFieldMetadataLoadingWithoutResponse,
      isGetCustomFieldTypeIDLoadingWithoutResponse,
      isGetCustomFieldsLoadingWithoutResponse,
    ],
  );

  const customFieldOptions = useMemo(
    () => erroredCustomFieldsOptions ?? customFields?.CustomFieldOptions,
    [customFields?.CustomFieldOptions, erroredCustomFieldsOptions],
  );

  const editableCustomFields = useEditableCustomFields(
    type,
    customFieldOptions,
    customFieldMetadata,
  );

  const onCreateOrUpdateError = useCallback((error?: EVA.Core.ServiceError) => {
    if (error?.Data?.Errors) {
      setErrors(
        Object.keys(error?.Data?.Errors ?? {})?.reduce(
          (accumulator, current) => {
            const customFieldID = parseInt(current);

            if (isNaN(customFieldID)) return accumulator;

            return {
              ...accumulator,
              [customFieldID]: error?.Data?.Errors?.[customFieldID]?.Message,
            };
          },
          {} as { [key: number]: string },
        ),
      );

      // The Error.Data object holds the correct custom field options for all editable custom fields
      // related to current organization unit
      setErroredCustomFieldsOptions(error?.Data?.CustomFieldOptions);
    }
  }, []);

  const refetchCustomFields = useCallback(() => {
    refetchGetCustomFields();
    refetchGetCustomFieldMetadata();
    setErrors(undefined);
  }, [refetchGetCustomFieldMetadata, refetchGetCustomFields]);

  const getCustomFieldByID = useCallback(
    (ID?: number) => {
      return editableCustomFields?.find((cf) => cf.CustomFieldID === ID);
    },
    [editableCustomFields],
  );

  const getCustomFieldErrorsByID = useCallback(
    (ID?: number) => (ID ? errors?.[ID] : undefined),
    [errors],
  );

  return {
    customFields: editableCustomFields,
    isCustomFieldsLoading,
    errors,
    onCreateOrUpdateError,
    refetchCustomFields,
    getCustomFieldByID,
    getCustomFieldErrorsByID,
    isCustomFieldsLoadingWithoutResponse,
  };
}

export interface IUseCustomFieldByIDReturnType {
  error: string | undefined;
  customField: EVA.Core.CustomFieldResponse | undefined;
  refetchCustomFields: () => void;
  isCustomFieldsLoading: boolean;
  onCreateOrUpdateError: (error?: EVA.Core.ServiceError | undefined) => void;
  allCustomFields: EVA.Core.CustomFieldResponse[];
}

export function useCustomFieldByID(
  type: CustomFieldType,
  ID?: number,
  ouId?: number,
): IUseCustomFieldByIDReturnType {
  const {
    customFields,
    getCustomFieldByID,
    getCustomFieldErrorsByID,
    isCustomFieldsLoading,
    onCreateOrUpdateError,
    refetchCustomFields,
  } = useCustomFields(type, ouId);

  const customField = useMemo(() => getCustomFieldByID(ID), [ID, getCustomFieldByID]);

  const error = useMemo(() => getCustomFieldErrorsByID(ID), [ID, getCustomFieldErrorsByID]);

  return {
    error,
    customField,
    refetchCustomFields,
    isCustomFieldsLoading,
    onCreateOrUpdateError,
    allCustomFields: customFields,
  };
}
